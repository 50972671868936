// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-drug-js": () => import("./../src/templates/drug.js" /* webpackChunkName: "component---src-templates-drug-js" */),
  "component---src-templates-substance-js": () => import("./../src/templates/substance.js" /* webpackChunkName: "component---src-templates-substance-js" */)
}

